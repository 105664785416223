// modal

document.addEventListener('DOMContentLoaded', () => {
	const btns = document.querySelectorAll('.main-btn');
	const modal = document.querySelector('.register');

	function OpenWindow(targetWindow) {
		$(targetWindow).arcticmodal({
			closeOnEsc: false,
			closeOnOverlayClick: false,
			openEffect: {
				type: "fade"
			},
			overlay: {
				css: {
					backgroundColor: "#000",
					opacity: .60
				}
			}
		});
	}


	btns.forEach(btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault()
			OpenWindow(".js-order_window");
		})
	})

})

function showHidePassword(target) {
    let input = document.getElementById('password-input');
    if (input.getAttribute('type') == 'password') {
        target.classList.add('view');
        input.setAttribute('type', 'text');
    } else {
        target.classList.remove('view');
        input.setAttribute('type', 'password');
    }
    return false;
}

window.showHidePassword = showHidePassword;